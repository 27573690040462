.file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}

.dropzone {
    width: 100%;
    height: 200px;
    border: 2px dashed #007bff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #007bff;
    cursor: pointer;
    padding: 1rem;
}

.upload-button {
    padding: 0.5rem 1rem;
    background-color: var(--btnBg);
    color: var(--btnText);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    gap: 10px;
    display: flex;
}

.upload-button:disabled {
    background-color: #153D77;
    color: white;
    cursor: not-allowed;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-message {
    color: red;
    margin-top: 1rem;
}

.previews {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
}

.preview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview img {
    max-width: 150px;
    max-height: 150px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropzone p {
    margin: 0;
    color: var(--textLight);
}

/* FileUpload.css */

.preview {
    position: relative;
    display: inline-block;
    margin: 10px;
}

.remove-button {
    position: absolute;
    top: -11px;
    right: -11px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: red;
}

#directIncome {
    background: var(--bgColor);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input1 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.input1 label {
    font-weight: bold;
}

.input1 input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

#directIncomeDiv {
    margin-top: 10px;
    padding: 10px;
    /* border: 1px solid var(--primary); */
    background: var(--bgColor);
    border-radius: 5px;    
}

.read-more-btn {
    color: blue;
    cursor: pointer;
    font-size: 0.9rem;
    border: none;
    background: none;
    padding: 0;
    margin-left: 5px;
    text-decoration: underline;
  }

  .block {
    margin-top: 5px;
    border-radius: 5px;
    padding: 0px 2px;
    border: 0;
    position: relative;
    bottom: 16px;
    left: 3px;
  }

  .bin-btn { 
    font-size: 22px;
    font-weight: 400;
    color:red;
    padding: 6px;
    /* background: #2c835a; */
    border: 0;
    border-radius: 5px;
    margin-left: 2px;
  }

  .bin-btn:hover  {
    color:red;
  }
