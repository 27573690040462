.container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  overflow-x: auto; /* Add horizontal scroll for small screens */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-break: break-word; /* Allow long content to break into multiple lines */
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}

.approve-btn,
.reject-btn,
.save-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  margin: 10px;
  width: 90%;
}

.reject-btn {
  background-color: #f44336;
}

.rejection-input {
  width: 100%;
  padding: 6px;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.checkButton {
  display:flex;
  justify-content: space-around;
  gap:7px;
}

.checkbox-cell {
  display: flex;
  align-items: center;
}

.checkbox-label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 7px;
  background-color: #153D77 !important;
  width:68px;
  height: 30px !important;
}

.checkbox-label1 {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 7px;
  background-color: #153D77 !important;
  width:28px !important;
  height: 28px !important;
  border-radius:5px;
}

.checkbox-input {
  display: none;
}

.checkbox-text {
  margin-left: 22px;
  font-size:12px;
}

.custom-checkbox {
  display:inline-block;
  position: absolute !important;
  top: 6px;
  left: 6px;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #ddd !important;
  border-radius: 3px;
}

.custom-checkbox::after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox.checked {
  background-color: #4caf50;
  border-color: #4caf50;
}

.custom-checkbox.checked::after {
  display: block;
  left: 5px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.button-container {
  display: flex;
  justify-content: space-between;
      width: 50%;
      align-content: space-between;
      align-items:center ;
}

.action-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.action-btn:last-child {
  background-color: #f44336;
}

.action-btn:hover {
  opacity: 0.8;
}

.actionBtn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  width:100%;
}

.actionBtn:hover {
  opacity: 0.8;
}

.button-container1 {
  display: flex;
  justify-content: space-between;
      width: 70%;
      align-content: space-between;
      align-items:center ;
      gap:2px;
}

.action-btn1 {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 3px 6px;
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
}

.action-btn1:last-child {
  background-color: #f44336;
}

.action-btn1:hover {
  opacity: 0.8;
}

.rejection-reason-container{
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #000000c7;
  height: 100vh;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  align-self: center;
  right: 0px;
  top: 0px;
  border-radius: 10px;
  z-index: 99;
}
.reject-container {
  background: #d9d9d9;
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: center;
    height: 50%;
    border-radius: 10px;
}
.rejection-reason-input{
  height: 100px;
  width: 90%;
  margin-bottom: 20px;
  border-radius: 10px;
}
td p{
  font-weight: 100;
  font-size: small;
}
.pgTitle {
  text-align: center;
  background-color: #fff;
  width: 100%;
  padding: 15px 0;
}

.amount {
  font-weight:500;
  font-size: 17px;;
}

.popImg {
  max-width: 300px;
  max-height: 300px;
}

.close-btn {
  position: absolute;
  top: 3px;
  right: 8px;
  background: white;
  border: none;
  border-radius: 16px;
  font-size: 23px;
  height: 30px;
  width: 30px;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .reject-container {
    height: 25%;
    width: 70%;
  }
}

@media(max-width:480px) {
  .action-btn1 {
    font-size:10px; 
  }

  .actionBtn {
    padding: 7px 9px !important;
    font-size: 9px !important;
  }

}

@media (max-width: 375px) {
  .actionBtn {
    padding: 7px 9px !important;
    font-size: 9px !important;
  }
}