.chartDetail {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .chartDetail div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  
  .chartDetail p {
    margin: 0;
    color: var(--textHeading);
    font-size: 12px;
  }
  
  .capping {
    align-items: center;
    background: white;
  box-shadow: 0px 0px 12px 0px #1d438347;
  margin: 0px;
  border-radius: 10px;
  padding: 12px 12px;
  color: black;
  margin-top:25px;
  }
  
  .cappingDetails {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
  }
  
  .cappingDetails hr {
    background: var(--textColor);
    transform: rotate(90deg);
    width: 50px;
    margin: 0;
    padding: 0;
  }
  
  .cappingDetails h1 {
    font-size: 25px;
    color: var(--colorPrimary);
    font-weight: 700;
    margin: 0;
  }
  
  .cappingDetails p {
    font-size: 15px;
    color: var(--textColor);
    margin: 0;
  }
  
  .cappingDetails div {
    text-align: center;
    margin-bottom: 8px;

  }
  
  .cappingDetailsDashboard > div {
    display: flex;
    flex-direction: column;
  }
  
  .cappingGraphDiv {
    display: flex;
    align-items: center;
    min-width: 50%;
    justify-content: space-around;
    margin-bottom: 15px;
  }
  
  .cappingAbout div {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 10px 0;
  }
  
  .cappingAbout p {
    color: var(--textColor);
    font-size: 12px;
    margin: 0;
  }
  
  .cappingAbout span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  
  #OrderAmount {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  #OrderAmount p {
    color: var(--textHeading);
    margin: 0;
    font-size: 13px;
    font-weight: 400;
  }
  
  #OrderAmount h5 {
    color: var(--textHeading);
    margin: 0;
    color: var(--colorPrimary);
    font-size: 15px;
  }
  
  @media (max-width: 1024px) {
    .capping {
      display: block;
    }
  
    .cappingDetails h1 {
      font-size: 14px;
      color: var(--colorPrimary);
      font-weight: 700;
      margin: 0;
    }
  
    .cappingDetails p {
      font-size: 9px;
      color: var(--textColor);
      margin: 0;
    }
  
    .cappingDetails {
      /* margin-top: 20px; */
      justify-content: space-between;
    }
  }
  
  .cappingDetailsDashboard h3 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    margin-top: 8px;
  }
  